<template>
  <header-auction :activeSearchDesktop="false" :activeSearchMobile="false" />
  <div>
    <!-- COMPONENTE DE CAROUSEL ICON -->
    <SliderTypeAnimal />

    <!-- SECCION ENCUENTRA LA FINCA IDEAL PARA TI -->
    <div class="flex items-center bg-agrogo-yellow-1 h-16 md:justify-center">
      <p
        class="font-nexa text-agrogo-green-2 font-black text-base md:text-2xl text-left ml-3 md:ml-0"
      >
        <span class="font-dinpro font-normal">Encuentra la Subasta </span
        >Perfecta para ti
      </p>
      <button
        type="button"
        class="btn-primary flex items-center justify-center h-9 bg-agrogo-green-3 rounded-xl font-nexa text-sm text-agrogo-yellow-1 w-32 md:text-base md:ml-5"
        :disabled="filterIsDisabled"
      >
        Filtrar
        <span class="icon-filter text-3xs pl-2 md:text-xs"></span>
      </button>
      <button
        @click="delAllFilter"
        type="button"
        class="btn-primary flex items-center justify-center h-9 bg-agrogo-green-3 rounded-xl font-nexa text-sm text-agrogo-yellow-1 w-32 md:text-base ml-1 md:ml-5 mr-3 md:mr-0"
        :disabled="filterIsDisabled"
      >
        Borrar
        <span class="icon-refresh text-3xs pl-2 md:text-xs"></span>
      </button>
    </div>
    <!-- CONTENEDOR DE FILTROS -->
    <div class="bg-agrogo-green-2 pb-10 pt-5 xl:pt-5">
      <!-- FILTROS SELECCIONADOS -->
      <div v-if="filterSelect.length > 0">
        <div class="flex md:justify-center">
          <div class="w-full md:w-9/12 relative flex py-2 items-center">
            <span
              class="flex-shrink mx-4 text-white text-lg font-nexa font-normal"
              >Filtros seleccionados</span
            >
            <div
              class="flex-grow border border-agrogo-yellow-1 mr-3 xl:mr-0"
            ></div>
          </div>
        </div>
        <div class="flex justify-center mb-5">
          <div class="w-10/12 xl:w-9/12 relative flex py-2">
            <div class="flex flex-wrap">
              <div
                class="border border-agrogo-yellow-1 mr-3 py-1 px-2 mt-2 rounded-lg"
                v-for="item in filterSelect"
                :key="item.id"
              >
                <p
                  class="text-agrogo-yellow-1 font-nexa font-normal flex text-xs xl:text-lg"
                >
                  {{ item.text }}
                  <button
                    @click="
                      deleteSelectFilter(
                        item.id,
                        item.typeFilter,
                        item.selectId
                      )
                    "
                    class="icon-x text-agrogo-yellow-1 text-xs xl:text-sm transform transition-all ml-2 border-none mt-0"
                  ></button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex md:justify-center mb-5">
          <div class="w-full md:w-9/12 relative flex py-2 items-center">
            <span
              class="flex-shrink mx-4 text-white text-lg font-nexa font-normal"
              >Lista de filtros</span
            >
            <div
              class="flex-grow border border-agrogo-yellow-1 mr-3 xl:mr-0"
            ></div>
          </div>
        </div>
      </div>
      <!--ACTIVAR O DESACTIVAR FORMULARIO TIPO DE FINCA -->
      <div class="flex md:justify-center">
        <div
          class="w-full mx-3 mx-md:0 md:w-9/12 bg-agrogo-green-5 h-8 md:h-14 flex justify-between items-center"
        >
          <div class="flex w-1/2 ml-2 justify-start">
            <a class="text-white text-lg md:text-2xl font-dinpro">
              <span
                class="icon-flower text-white text-sm ml-1.5 mr-2 transform transition-all"
              ></span>
              <span class="bold-text sm:ml-2">Tipo de Animal</span>
            </a>
          </div>
          <button
            @click="typeFarm"
            class="h-8 md:h-14 w-14 bg-agrogo-yellow-1 btn-primary rounded-none py-0"
          >
            <span
              v-if="!stateFilter.typeFarm"
              class="icon-Publicar text-black text-sm md:text-2xl transform transition-all"
            ></span>
            <span
              v-else
              class="icon-menos text-black text-sm md:text-2xl transform transition-all"
            ></span>
          </button>
        </div>
      </div>
      <!-- FORMULARIO TIPO DE ANIMAL -->
      <div v-if="stateFilter.typeFarm" class="flex justify-center">
        <div
          class="w-full mx-3 mx-md:0 xl:w-9/12 flex xl:justify-between items-center mt-5"
        >
          <div
            class="m-auto grid grid-cols-3 xl:grid-cols-5 gap-3 xl:gap-5 w-full place-items-center xl:w-5/6 xl:mx-auto"
          >
            <div
              class="card-farm-filter"
              :id="'card-farm-filter' + item.id"
              v-for="item in dataIcon"
              :key="item.id"
              @click.prevent="addRemove(item.id, item.name)"
            >
              <div class="flex flex-col items-center">
                <div class="text-2xl md:text-5xl mb-2 mt-3 md:mt-10">
                  <span :class="item.icon"></span>
                </div>
                <a
                  class="font-nexa font-normal mb-2 text-xs md:text-lg text-white pt-2 md:pt-0"
                  >{{ item.name }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ACTIVAR O DESACTIVAR FORMULARIO UBICACION  -->
      <div class="flex justify-center">
        <div
          class="w-full mx-3 mx-md:0 md:w-9/12 bg-agrogo-green-5 h-8 md:h-14 flex justify-between items-center mt-7"
        >
          <div class="flex w-1/2 ml-2 justify-start">
            <a class="text-white text-lg md:text-2xl font-dinpro">
              <span
                class="icon-ubicacion text-white text-base md:text-2xl ml-1.5 mr-3 transform transition-all"
              ></span>
              <span class="bold-text sm:ml-2">Ubicación</span>
            </a>
          </div>
          <button
            class="h-8 md:h-14 w-14 bg-agrogo-yellow-1 btn-primary rounded-none py-0"
            @click="locationFarm"
          >
            <span
              v-if="!stateFilter.location"
              class="icon-Publicar text-black text-sm md:text-2xl transform transition-all"
            ></span>
            <span
              v-else
              class="icon-menos text-black text-sm md:text-2xl transform transition-all"
            ></span>
          </button>
        </div>
      </div>
      <!-- CONTAINER DEPARTAMENTO MUNICIPIO-->
      <div v-if="stateFilter.location" class="flex justify-center mt-3">
        <div class="w-full md:w-9/12">
          <div class="grid gap-4 grid-cols-1 xl:grid-cols-2">
            <div class="bg-agrogo-green-5-filter-farm xl:pb-4">
              <div class="grid xl:gap-2 grid-cols-2">
                <div>
                  <div class="input-flex-column">
                    <div class="">
                      <p
                        class="text-white sm:text-lg font-nexa font-normal mt-1 xl:mt-4 mr-1 ml-11 xl:ml-16"
                      >
                        Departamento
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex justify-start">
                  <div class="mt-2 xl:mt-4 mr-8 flex justify-start">
                    <v-select
                      :options="departments"
                      v-model="selectedDepart"
                      :reduce="(option) => option.id"
                      class="style-chooser2 bg-white rounded-full style-chooser-farm ml-1 sm:ml-0 input-filter-farm-width sm:w-56 style-chooser-listoffarms"
                      label="name"
                      id="departments"
                      @onChange="loadMunicipies()"
                    >
                      <template #no-options>Sin opciones</template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-agrogo-green-5-filter-farm xl:pb-4">
              <div class="grid xl:gap-2 grid-cols-2">
                <div>
                  <div class="input-flex-column">
                    <div class="flex">
                      <p
                        class="text-white sm:text-lg font-nexa font-normal mt-1 xl:mt-4 mr-1 ml-11 xl:ml-16"
                      >
                        Municipio
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex justify-start">
                  <div class="mt-2 xl:mt-4 mr-8">
                    <v-select
                      :options="municipalities"
                      v-model="selectedMunicipality"
                      id="municipies"
                      @onChange="dataMunicipies()"
                      class="style-chooser2 input-filter-farm-width sm:w-56 bg-white rounded-full style-chooser-farm ml-1 sm:ml-0 style-chooser-listoffarms"
                      label="name"
                    >
                      <template #no-options>Sin opciones</template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ACTIVAR O DESACTIVAR FORMULARIO PRECIO -->
      <div class="flex justify-center">
        <div
          class="w-full mx-3 mx-md:0 md:w-9/12 bg-agrogo-green-5 h-8 md:h-14 flex justify-between items-center mt-7"
        >
          <div class="flex w-1/2 ml-2 justify-start">
            <a class="text-white text-lg md:text-2xl font-dinpro">
              <span
                class="icon-signo-pesos text-white text-base md:text-2xl ml-1.5 mr-3 transform transition-all"
              ></span>
              <span class="bold-text sm:ml-2">Precio</span>
            </a>
          </div>
          <button
            class="h-8 md:h-14 w-14 bg-agrogo-yellow-1 btn-primary rounded-none py-0"
            @click="moneyFarm"
          >
            <span
              v-if="stateFilter.money"
              class="icon-menos text-black text-sm md:text-2xl transform transition-all"
            ></span>
            <span
              v-else
              class="icon-Publicar text-black text-sm md:text-2xl transform transition-all"
            ></span>
          </button>
        </div>
      </div>
      <!-- CONTAINER  PRECIO-->
      <div v-if="stateFilter.money" class="flex justify-center">
        <!-- FILTRO POR HECTAREAS-->
        <div
          class="w-full md:w-9/12 bg-agrogo-green-5-filter-farm pt-6 pb-5 lg:mt-3 lg:pt-7 lg:pb-10"
        >
          <div
            class="grid grid-cols-1 lg:grid-cols-3 mx-11 gap-y-5 place-content-between"
          >
            <div class="flex items-center text-left">
              <p
                class="text-white text-sm font-nexa font-normal md:text-lg leading-none"
              >
                Precio Actual de la Subasta <br />

                <span class="text-agrogo-gray-1 text-xs">
                  (Pesos Colombianos)
                </span>
              </p>
            </div>
            <div class="grid grid-cols-2 lg:grid-cols-1">
              <div class="text-left lg:pl-6 xl:pl-16">
                <p
                  class="text-white font-dinpro font-normal text-xs sm:text-base"
                >
                  Desde
                </p>
              </div>
              <div class="flex items-center justify-start md:justify-center">
                <input
                  id="areaFrom"
                  placeholder="0"
                  v-model="dataMoney.areaFrom"
                  @keyup="
                    addEditDelSelectFilter(
                      null,
                      'areaFrom',
                      dataMoney.areaFrom,
                      'areaFrom'
                    )
                  "
                  type="text"
                  class="input-standard input-filter-farm-width sm:w-56 ml-3 xl:ml-0"
                />
              </div>
            </div>
            <div class="grid grid-cols-2 lg:grid-cols-1">
              <div class="text-left lg:pl-6 xl:pl-16">
                <p
                  class="text-white font-dinpro font-normal text-xs sm:text-base text-left"
                >
                  Hasta
                </p>
              </div>
              <div class="flex items-center justify-start md:justify-center">
                <input
                  id="areaUntil"
                  placeholder="0"
                  @keyup="
                    addEditDelSelectFilter(
                      null,
                      'areaUntil',
                      dataMoney.areaUntil,
                      'areaUntil'
                    )
                  "
                  v-model="dataMoney.areaUntil"
                  type="text"
                  class="input-standard input-filter-farm-width sm:w-56 ml-3 xl:ml-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ACTIVAR O DESACTIVAR FORMULARIO CARACTERÍSTICAS  -->
      <div class="flex justify-center">
        <div
          class="w-full mx-3 mx-md:0 md:w-9/12 bg-agrogo-green-5 h-8 md:h-14 flex justify-between items-center mt-7"
        >
          <div class="flex w-1/2 ml-2 justify-start">
            <a class="text-white text-lg md:text-2xl font-dinpro">
              <span
                class="icon-document text-white text-base md:text-2xl ml-1.5 mr-3 transform transition-all"
              ></span>
              <span class="bold-text sm:ml-2">Características</span>
            </a>
          </div>
          <button
            class="h-8 md:h-14 w-14 bg-agrogo-yellow-1 btn-primary rounded-none py-0"
            @click="characteristicFarm()"
          >
            <span
              v-if="!stateFilter.characteristic"
              class="icon-Publicar text-black text-sm md:text-2xl transform transition-all"
            ></span>
            <span
              v-else
              class="icon-menos text-black text-sm md:text-2xl transform transition-all"
            ></span>
          </button>
        </div>
      </div>
      <!-- FORMULARIO CARACTERISTICAS -->
      <div
        v-if="stateFilter.characteristic"
        class="flex justify-center xl:mt-3"
      >
        <div class="w-full md:w-9/12">
          <div class="grid grid-cols-1 xl:grid-cols-2 gap-x-3">
            <!-- FILTRO DE RAZA-->
            <div class="grid grid-cols-1 gap-y-3 pt-6 pb-8 xl:pt-0 xl:pb-32">
              <div
                class="grid grid-cols-2 bg-agrogo-green-5-filter-farm xl:h-16 px-11"
              >
                <div class="flex items-center text-left">
                  <p
                    class="text-white text-sm font-nexa font-normal lg:text-lg"
                  >
                    Raza
                  </p>
                </div>
                <div class="flex items-center">
                  <v-select
                    class="style-chooser2 input-filter-farm-width sm:w-56 bg-white rounded-full style-chooser-farm ml-1 sm:ml-0"
                    label="name"
                    id="departments"
                  >
                    <template #no-options>Sin opciones</template>
                  </v-select>
                </div>
              </div>
              <!-- FILTRO DE Puro / Comercial-->
              <div
                class="grid grid-cols-2 bg-agrogo-green-5-filter-farm xl:h-16 px-11"
              >
                <div class="flex items-center text-left">
                  <p
                    class="text-white text-sm font-nexa font-normal lg:text-lg"
                  >
                    Puro / Comercial
                  </p>
                </div>
                <div class="flex items-center">
                  <v-select
                    class="style-chooser2 input-filter-farm-width sm:w-56 bg-white rounded-full style-chooser-farm ml-1 sm:ml-0"
                    label="name"
                    id="departments"
                  >
                    <template #no-options>Sin opciones</template>
                  </v-select>
                </div>
              </div>
              <!-- FILTRO DE Tipo-->
              <div
                class="grid grid-cols-2 bg-agrogo-green-5-filter-farm xl:h-16 px-11"
              >
                <div class="flex items-center text-left">
                  <p
                    class="text-white text-sm font-nexa font-normal lg:text-lg"
                  >
                    Tipo
                  </p>
                </div>
                <div class="flex items-center">
                  <v-select
                    class="style-chooser2 input-filter-farm-width sm:w-56 bg-white rounded-full style-chooser-farm ml-1 sm:ml-0"
                    label="name"
                    id="departments"
                  >
                    <template #no-options>Sin opciones</template>
                  </v-select>
                </div>
              </div>
            </div>
            <div>
              <!-- FILTRO Peso Promedio x Animal (Kg)-->
              <div class="bg-agrogo-green-5-filter-farm xl:h-40">
                <div class="grid xl:gap-x-2 grid-cols-1 xl:grid-cols-2 xl:py-4">
                  <div
                    class="flex items-center text-left px-11 xl:px-8 pb-4 xl:pb-0"
                  >
                    <p class="text-white sm:text-lg font-nexa font-normal">
                      Peso Promedio x Animal
                      <span class="text-agrogo-gray-1 text-xs lg:text-base"
                        >(Kg)</span
                      >
                    </p>
                  </div>
                  <div class="grid grid-cols-1 gap-y-4 xl:gap-y-2">
                    <div class="grid grid-cols-2 xl:grid-cols-1">
                      <p
                        class="text-agrogo-gray-1 font-dinpro font-normal text-xs sm:text-base ml-11 xl:ml-3 text-left mr-2 xl:mr-0"
                      >
                        Desde
                      </p>
                      <input
                        id="heightFrom"
                        placeholder="0"
                        v-model="heightFrom"
                        @keyup="
                          addEditDelSelectFilter(
                            null,
                            'heightFrom',
                            heightFrom,
                            'heightFrom'
                          )
                        "
                        type="text"
                        class="input-standard input-filter-farm-width sm:w-56"
                      />
                    </div>
                    <div class="grid grid-cols-2 xl:grid-cols-1">
                      <p
                        class="text-agrogo-gray-1 font-dinpro font-normal text-xs sm:text-base ml-11 xl:ml-3 text-left mr-2 xl:mr-0"
                      >
                        Hasta
                      </p>
                      <input
                        id="heightUntil"
                        v-model="heightUntil"
                        placeholder="0"
                        @keyup="
                          addEditDelSelectFilter(
                            null,
                            'heightUntil',
                            heightUntil,
                            'heightUntil'
                          )
                        "
                        type="text"
                        class="input-standard input-filter-farm-width sm:w-56"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- FILTRO Número de Animales-->
              <div class="bg-agrogo-green-5-filter-farm xl:h-40 mt-6 xl:mt-3">
                <div class="grid xl:gap-x-2 grid-cols-1 xl:grid-cols-2 xl:py-4">
                  <div
                    class="flex items-center text-left px-11 xl:px-8 pb-4 xl:pb-0"
                  >
                    <p class="text-white sm:text-lg font-nexa font-normal">
                      Número de Animales
                    </p>
                  </div>
                  <div class="grid grid-cols-1 gap-y-4 xl:gap-y-2">
                    <div class="grid grid-cols-2 xl:grid-cols-1">
                      <p
                        class="text-agrogo-gray-1 font-dinpro font-normal text-xs sm:text-base ml-11 xl:ml-3 text-left mr-2 xl:mr-0"
                      >
                        Desde
                      </p>
                      <input
                        id="heightFrom"
                        placeholder="0"
                        v-model="heightFrom"
                        @keyup="
                          addEditDelSelectFilter(
                            null,
                            'heightFrom',
                            heightFrom,
                            'heightFrom'
                          )
                        "
                        type="text"
                        class="input-standard input-filter-farm-width sm:w-56"
                      />
                    </div>
                    <div class="grid grid-cols-2 xl:grid-cols-1">
                      <p
                        class="text-agrogo-gray-1 font-dinpro font-normal text-xs sm:text-base ml-11 xl:ml-3 text-left mr-2 xl:mr-0"
                      >
                        Hasta
                      </p>
                      <input
                        id="heightUntil"
                        v-model="heightUntil"
                        placeholder="0"
                        @keyup="
                          addEditDelSelectFilter(
                            null,
                            'heightUntil',
                            heightUntil,
                            'heightUntil'
                          )
                        "
                        type="text"
                        class="input-standard input-filter-farm-width sm:w-56"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderAuction from "@/components/header/HeaderAuction";
import SliderTypeAnimal from "@/components/slider/SliderTypeAnimal";
import { generateHash } from "@/utils";
import { mapState } from "vuex";
export default {
  inject: ["provider"],
  name: "FarmFilter",
  components: {
    SliderTypeAnimal,
    HeaderAuction,
  },
  data() {
    return {
      filterIsDisabled: true,
      stateFilter: [
        { typeFarm: false },
        { location: false },
        { money: false },
        { characteristic: false },
      ],
      selected: [],
      municipalities: [],
      departments: [],
      selectedDepart: null,
      selectedMunicipality: null,
      selectedCercMunicipality: null,
      dataMoney: {
        areaUntil: null,
        areaFrom: null,
        priceHectareFrom: null,
        priceHectareUntil: null,
        priceTotalFrom: null,
        priceTotalUntil: null,
        barterPercentage: 0,
      },
      selectedLandUse: [],
      selectedTypography: [],
      selectedWater: [],
      heightFrom: null,
      heightUntil: null,
      dataLandUse: [
        { id: 1, name: "Rural" },
        { id: 2, name: "Urbana" },
        { id: 3, name: "Semi Urbana" },
      ],
      dataTypography: [
        { id: 1, name: "Plana" },
        { id: 2, name: "Ondulada" },
        { id: 3, name: "Ondulada suave" },
        { id: 4, name: "Cerros" },
        { id: 5, name: "Reserva natural" },
      ],
      dataWater: [
        { id: 1, name: "Ríos" },
        { id: 2, name: "Nacimiento" },
        { id: 3, name: "Jabueyes" },
        { id: 4, name: "Riego" },
        { id: 5, name: "Riachuelo" },
        { id: 6, name: "Ciénaga" },
        { id: 7, name: "Acueducto" },
        { id: 8, name: "Pozo Profundo" },
      ],
      filterSelect: [],
      dataIcon: [
        { id: 1, icon: "icon-ganadera", name: "Bovinos" },
        { id: 2, icon: "icon-equinos", name: "Equinos" },
        { id: 3, icon: "icon-porcicola", name: "Porcinos" },
        { id: 4, icon: "icon-asnos", name: "Asnales" },
        { id: 5, icon: "icon-ovinos", name: "Ovinos y Caprinos" },
        { id: 6, icon: "icon-mula", name: "Mulares" },
        { id: 7, icon: "icon-caninos", name: "Caninos" },
        { id: 8, icon: "icon-felinos", name: "Felinos" },
        { id: 9, icon: "icon-avicola", name: "Aves" },
        { id: 10, icon: "icon-piscicola", name: "Peces" },
      ],
      isBusy: false,
      repository: this.provider.publishFarmRepository,
    };
  },
  watch: {
    selectedDepart() {
      const me = this;
      me.loadMunicipies(me.selectedDepart);
    },
    //ACTUALIZA O ELIMINA EL FILTRO SELECCIONADO DE MUNICIPIO
    selectedMunicipality() {
      const me = this;
      if (me.selectedMunicipality) {
        this.addEditDelSelectFilter(
          null,
          "filterMunicipie",
          me.selectedMunicipality.name,
          "municipies"
        );
      } else {
        this.deleteSelectFilter(null, "filterMunicipie", "municipies");
      }
    },
  },
  computed: {
    ...mapState("publishFarm", ["step"]),
    isValidationMunicality() {
      const me = this;
      let is_error = false;
      if (
        me.selectedMunicipality.id !== null &&
        me.selectedCercMunicipality !== null &&
        me.selectedMunicipality.id == me.selectedCercMunicipality
      ) {
        is_error = true;
      } else {
        is_error = false;
      }
      return is_error;
    },
  },
  methods: {
    //GET DEPARTAMENTOS Y MUNICIPIOS
    async loadDepartments() {
      const me = this;
      try {
        const data_aditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllDeparts(data_aditional);
        me.departments = data;
      } catch (error) {
        // console.log(error);
      }
    },
    //GET MUNICIPIOS
    async loadMunicipies(id) {
      const me = this;
      try {
        me.resetSelect();
        const data_aditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllMuniciByDepart(id, data_aditional);
        me.municipalities = data.municipalities;
        this.addEditDelSelectFilter(
          null,
          "filtroDepartamento",
          data.name,
          "departments"
        );
      } catch (error) {
        // console.log(error);
      }
    },
    //ACTIVA Y DESACTIVA EL FORMULARIO TYPE DE FINCA
    typeFarm() {
      if (this.stateFilter.typeFarm) {
        this.stateFilter.typeFarm = false;
      } else {
        this.stateFilter.typeFarm = true;
      }
    },
    //ACTIVA Y DESACTIVA EL FORMULARIO UBICACIÓN
    locationFarm() {
      if (this.stateFilter.location) {
        this.stateFilter.location = false;
      } else {
        this.stateFilter.location = true;
      }
    },
    //ACTIVA Y DESACTIVA EL FORMULARIO DINERO
    moneyFarm() {
      if (this.stateFilter.money) {
        this.stateFilter.money = false;
      } else {
        this.stateFilter.money = true;
      }
    },
    //ACTIVA Y DESACTIVA EL FORMULARIO DINERO
    characteristicFarm() {
      if (this.stateFilter.characteristic) {
        this.stateFilter.characteristic = false;
      } else {
        this.stateFilter.characteristic = true;
      }
    },
    //CAMBIA LOS ESTILOS DEL ITEM SELECCIONADO DE FILTRO TIPO DE FINCA
    addRemove(id, name = null) {
      const me = this;
      const card_id = document.getElementById("card-farm-filter" + id);
      if (card_id) {
        if (card_id.classList[1]) {
          card_id.classList.remove("active-card-farm-filter");
          me.selected.forEach((el, index) => {
            if (el == id) me.selected.splice(index, 1);
          });
        } else {
          me.selected.push(id);
          card_id.classList.add("active-card-farm-filter");
        }
        this.addEditDelSelectFilter(
          id,
          "filterTypeFarm",
          name,
          "card-farm-filter" + id
        );
      }
    },
    //FORMATEA LAS PROPIEDADES DE LOS INPUT CON PUNTOS DE MIL
    formatInput(typeFilter) {
      const me = this;
      if (typeFilter == "areaFrom") {
        me.dataMoney.areaFrom = me.dataMoney.areaFrom.split(".").join("");
        me.dataMoney.areaFrom = me.formatThousand(me.dataMoney.areaFrom);
      }
      if (typeFilter == "areaUntil") {
        me.dataMoney.areaUntil = me.dataMoney.areaUntil.split(".").join("");
        me.dataMoney.areaUntil = me.formatThousand(me.dataMoney.areaUntil);
      }
      if (typeFilter == "priceHectareFrom") {
        me.dataMoney.priceHectareFrom = me.dataMoney.priceHectareFrom
          .split(".")
          .join("");
        me.dataMoney.priceHectareFrom = me.formatThousand(
          me.dataMoney.priceHectareFrom
        );
      }
      if (typeFilter == "priceHectareUntil") {
        me.dataMoney.priceHectareUntil = me.dataMoney.priceHectareUntil
          .split(".")
          .join("");
        me.dataMoney.priceHectareUntil = me.formatThousand(
          me.dataMoney.priceHectareUntil
        );
      }
      if (typeFilter == "priceTotalFrom") {
        me.dataMoney.priceTotalFrom = me.dataMoney.priceTotalFrom
          .split(".")
          .join("");
        me.dataMoney.priceTotalFrom = me.formatThousand(
          me.dataMoney.priceTotalFrom
        );
      }
      if (typeFilter == "priceTotalUntil") {
        me.dataMoney.priceTotalUntil = me.dataMoney.priceTotalUntil
          .split(".")
          .join("");
        me.dataMoney.priceTotalUntil = me.formatThousand(
          me.dataMoney.priceTotalUntil
        );
      }
      if (typeFilter == "heightFrom") {
        me.heightFrom = me.heightFrom.split(".").join("");
        me.heightFrom = me.formatThousand(me.heightFrom);
      }
      if (typeFilter == "heightUntil") {
        me.heightUntil = me.heightUntil.split(".").join("");
        me.heightUntil = me.formatThousand(me.heightUntil);
      }
    },

    //DA FORMATO A LOS PRECIOS
    formatMoney(money) {
      const me = this;
      money = money.split(".").join("");

      if (money >= 0 && money <= 99999) {
        money = "$ " + me.formatThousand(money);
      }
      if (money >= 100000 && money <= 999999) {
        money = "$ " + parseInt(money / 1000) + " mil";
      }
      if (money >= 1000000 && money <= 1999999) {
        money = "$ " + parseInt(money / 1000) + " millón";
      }
      if (money >= 2000000) {
        money =
          "$ " + me.formatThousand(parseInt(money / 1000000)) + " millones";
      }
      return money;
    },

    //AGREGA, EDITA Y ELIMINA EL ITEM SELECCIONADOS
    addEditDelSelectFilter(
      id = null,
      typeFilter,
      text = null,
      selectId = null
    ) {
      const me = this;

      //FORMATEA CON PUNTOS DE MIL LOS INPUTS
      me.formatInput(typeFilter);

      var validation_creation = true;
      if (me.filterSelect.length > 0) {
        //SI EL ELEMENTO TIENE UN ID POR TANTO ES DE SELECCION MULTIPLE
        if (id != null) {
          me.filterSelect.forEach((element, index) => {
            //SI EL ELEMENTO ES EXISTE DEBE SER ELIMINADO YA QUE DEJÓ DE ESTAR SELECCIONADO
            if (
              element.id == id &&
              element.typeFilter == typeFilter &&
              element.selectId == selectId
            ) {
              validation_creation = false;
              me.filterSelect.splice(index, 1);
            }
          });
        } else {
          //VALIDA SI EL TEXT ENVIADO TIENE UN VALOR O SI NO SERÁ ELIMINADO
          if (text > 0 || text.length > 0) {
            me.filterSelect.forEach((element, index) => {
              //SI EL ELEMENTO ES EXISTE DEBE SER EDITADO
              if (
                element.id == id &&
                element.typeFilter == typeFilter &&
                element.selectId == selectId
              ) {
                //SI EL ELMENTO ENVIADO ES DE TIPO PRECIO
                if (
                  typeFilter == "priceHectareFrom" ||
                  typeFilter == "priceHectareUntil" ||
                  typeFilter == "priceTotalFrom" ||
                  typeFilter == "priceTotalUntil"
                ) {
                  text = me.formatMoney(text);
                }
                //SI EL ELMENTO ENVIADO ES DE AREA UNTIL O FROM
                if (typeFilter == "areaUntil" || typeFilter == "areaFrom") {
                  text = text + " ha";
                }
                //SI EL ELMENTO ENVIADO ES DE HEIGHT UNTIL O FROM
                if (typeFilter == "heightUntil" || typeFilter == "heightFrom") {
                  text = text + " msnm";
                }
                validation_creation = false;
                me.filterSelect[index].text = text;
              }
            });
          } else {
            validation_creation = false;
            me.deleteSelectFilter(null, typeFilter, selectId);
          }
        }
      }
      if (validation_creation && (text > 0 || text.length > 0)) {
        //SI EL ELMENTO ENVIADO ES DE AREA UNTIL O FROM
        if (typeFilter == "areaUntil" || typeFilter == "areaFrom") {
          text = me.formatThousand(text) + " ha";
        }
        //SI EL ELMENTO ENVIADO ES DE HEIGHT UNTIL O FROM
        if (typeFilter == "heightUntil" || typeFilter == "heightFrom") {
          text = me.formatThousand(text) + " msnm";
        }
        me.filterSelect.push({
          id: id,
          typeFilter: typeFilter,
          text: text,
          selectId: selectId,
        });
      }
      //HABILITA O DESABILITA LOS BOTONES DE FILTRADO Y LIMPIAR
      if (me.filterSelect.length > 0) {
        me.filterIsDisabled = false;
      } else {
        me.filterIsDisabled = true;
      }
    },
    //VALIDAR INPUT DE %
    inputBarter(value) {
      const me = this;
      if (value == "-") {
        value = me.dataMoney.barterPercentage - 1;
      }
      if (value == "+") {
        value = me.dataMoney.barterPercentage + 1;
      }
      if (value >= 0 && value <= 100) {
        me.dataMoney.barterPercentage = value;
        me.addEditDelSelectFilter(
          null,
          "barterPercentage",
          me.dataMoney.barterPercentage + " %",
          "barterPercentage"
        );
      } else {
        me.dataMoney.barterPercentage = null;
        me.deleteSelectFilter(null, "barterPercentage", "barterPercentage");
        me.$swal({
          icon: "info",
          text: "El porcentaje permitido es de 0% al 100%",
        });
      }
    },
    //DA FORMATO DE PUNTOS DE MIL
    formatThousand(number) {
      const formatterPeso = new Intl.NumberFormat("es-CO", {
        currency: "COP",
        minimumFractionDigits: 0,
      });
      number = formatterPeso.format(number);
      return number;
    },
    //ELIMINA LOS ELEMENTOS DE FILTRADO
    deleteSelectFilter(id = null, typeFilter, selectId = null) {
      const me = this;
      if (me.filterSelect.length > 0) {
        //SI EL ELEMENTO TIENE UN ID POR TANTO ES DE SELECCION MULTIPLE
        if (id != null) {
          me.filterSelect.forEach((element, index) => {
            if (element.typeFilter == typeFilter && element.id == id) {
              //SI LOS ELEMENTOS MULTIPLES SON DE TIPO DE FINCA
              if (typeFilter == "filterTypeFarm") {
                var card_id = document.getElementById("card-farm-filter" + id);
                if (card_id.classList[1]) {
                  card_id.classList.remove("active-card-farm-filter");
                  me.selected.forEach((el, index) => {
                    if (el == id) me.selected.splice(index, 1);
                  });
                }
              } else {
                //ELIMINAR SELECCIÓN DE CHECKBOX USO DE SUELOS
                if (typeFilter == "landUse") {
                  me.selectedLandUse.forEach((el, index) => {
                    if (el == id) me.selectedLandUse.splice(index, 1);
                  });
                }
                //ELIMINAR SELECCIÓN DE CHECKBOX TIPOGRAFIA
                if (typeFilter == "typography") {
                  me.selectedTypography.forEach((el, index) => {
                    if (el == id) me.selectedTypography.splice(index, 1);
                  });
                }
                //ELIMINAR SELECCIÓN DE CHECKBOX AGUAS
                if (typeFilter == "water") {
                  me.selectedWater.forEach((el, index) => {
                    if (el == id) me.selectedWater.splice(index, 1);
                  });
                }
              }
              me.filterSelect.splice(index, 1);
            }
          });
        } else {
          //SI EL ELEMENTO ES UNICO
          me.filterSelect.forEach((element, index) => {
            if (
              element.typeFilter == typeFilter &&
              element.selectId == selectId
            ) {
              //ELIMINAR VALOR SELECCIÓN DE DEPARTAMENTO
              if (selectId == "departments") {
                me.selectedDepart = null;
                me.selectedMunicipality = null;
                //ELIMINAR VALOR SELECCIÓN DE MUNICIPIO
              } else if (selectId == "municipies") {
                me.selectedMunicipality = null;
              } else {
                //ELIMINAR VALOR INPUT MEDIANTE EL ID
                let el = document.getElementById(selectId);
                el.value = "";
                el.dispatchEvent(new Event("input"));
              }
              me.filterSelect.splice(index, 1);
            }
          });
        }
        //HABILITA O DESABILITA LOS BOTONES DE FILTRADO Y LIMPIAR
        if (me.filterSelect.length > 0) {
          me.filterIsDisabled = false;
        } else {
          me.filterIsDisabled = true;
        }
      }
    },
    //ELIMINA LA DATA DEL MUNICIPIO SELECCIONADO
    resetSelect() {
      const me = this;
      me.municipalities = [];
      me.selectedMunicipality = null;
      me.selectedCercMunicipality = null;
    },
    //REINICIA TODOS LOS ESTILOS A TODAS LAS TARJETAS DE TIPO DE FINCA
    removeStylesAllCard() {
      const me = this;
      me.dataIcon.forEach((element) => {
        var card_id = document.getElementById("card-farm-filter" + element.id);
        if (card_id) {
          if (card_id.classList[1]) {
            card_id.classList.remove("active-card-farm-filter");
          }
        }
      });
    },
    //ELIMINA TODOS LOS FILTROS DE LA VISTA
    delAllFilter() {
      const me = this;
      me.removeStylesAllCard();
      me.resetSelect();
      me.dataMoney = {
        areaUntil: null,
        areaFrom: null,
        priceHectareFrom: null,
        priceHectareUntil: null,
        priceTotalFrom: null,
        priceTotalUntil: null,
        barterPercentage: 0,
      };
      me.filterSelect = [];
      me.selectedDepart = null;
      me.selected = [];
      me.selectedLandUse = [];
      me.selectedTypography = [];
      me.selectedWater = [];
      me.heightFrom = null;
      me.heightUntil = null;
      //DESABILITA LOS BOTONES DE FILTRADO Y LIMPIAR
      me.filterIsDisabled = true;
    },
  },
  mounted() {
    const me = this;
    me.loadDepartments();
  },
};
</script>

<style></style>
