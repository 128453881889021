<template>
  <p
    class="font-nexa font-normal text-xs text-white pl-8 text-left bg-agrogo-green-1 pt-2.5 md:text-lg md:px-24"
  >
    <!-- <span class="font-black">{{ category.name }}</span> -->
    <span class="font-black">Tipo de</span> Animal
  </p>
  <div
    class="flex w-auto h-auto bg-agrogo-green-1 pt-1.5 md:px-24 justify-center"
  >
    <div
      class="items-center px-2 overflow-x-auto flex space-x-1 md:space-x-3.5 md:py-0 md:pt-2"
    >
      <div
        class="card-slider w-20 h-22 overflow-hidden flex flex-col md:w-32 md:h-28"
        v-for="(item, index) in dataIcon"
        :key="index"
      >
        <span
          class="text-4xl text-agrogo-yellow-1 mb-0 md:text-5xl"
          :class="item.icon"
        ></span>
        <a
          class="font-nexa font-normal text-agrogo-gray-1 mb-2 text-xs md:text-base"
          >{{ item.name }}</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      /**
       * @type {Array<Object>}
       */
      dataIcon: [
        { id: 1, icon: "icon-ganadera", name: "Bovinos" },
        { id: 2, icon: "icon-equinos", name: "Equinos" },
        { id: 3, icon: "icon-porcicola", name: "Porcinos" },
        { id: 4, icon: "icon-asnos", name: "Asnales" },
        { id: 5, icon: "icon-ovinos", name: "Ovinos y Caprinos" },
        { id: 5, icon: "icon-mula", name: "Mulares" },
        { id: 6, icon: "icon-caninos", name: "Caninos" },
        { id: 7, icon: "icon-felinos", name: "Felinos" },
        { id: 8, icon: "icon-avicola", name: "Aves" },
        { id: 9, icon: "icon-piscicola", name: "Peces" },
      ],
    };
  },
};
</script>

<style></style>
